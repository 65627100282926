import React, { useContext, useEffect, useState } from 'react'
import Head from 'next/head'
import { BrPageContext } from '@bloomreach/react-sdk'
import { GoogleEnhancedEcommerce } from './GoogleEnhancedEcommerce'
import { useRouter } from "next/router";
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'

const ThirdPartyScripts = () => {
    const page = useContext(BrPageContext)
    const { setInitializationState } = useContext(GoogleEnhancedEcommerce)
    const [includeHero, setIncludeHero] = useState(false)

    const router = useRouter();
    const p = router.asPath.slice(1);
    const canonicalURL = `https://www.baldinini-shop.com/${p}`.split("?")[0];

    const translations = useTranslation()

    const {
        'GTM-Id': gtmid,
        'HERO-applicationID': heroid,
        'ONETRUST-Id': onetrustid,
        'googleSiteVerification': googleSiteVerification,
        disableGoogleEnhancedPushes = false
    } = page?.getChannelParameters() ?? {}

    // Google Tag Manager - initialize ID
    useEffect(() => {
        if ((!gtmid && typeof window !== 'undefined') || disableGoogleEnhancedPushes) {
            setInitializationState(false)
        }
    }, [gtmid])

    useEffect(() => {
        window.addEventListener('consentUpdatedHero', ({ detail }) => {
            if (({}).hasOwnProperty.call(detail, 'analytics_storage') && detail.analytics_storage === 'granted') {
                setIncludeHero(true)
            }
        })
    }, [])

    return (
            <Head>
                <title>Baldinini - {page.getTitle()}</title>
                <link rel="canonical" href={canonicalURL} />
                <meta name="description" content={page.getTitle() + ' - ' + translations('global:seo.page.description')}/>
                {googleSiteVerification && (<><meta name="google-site-verification" content={googleSiteVerification} /></>)}
                {gtmid && (
                    <>
                        <script
                            data-cookieconsent='ignore'
                            dangerouslySetInnerHTML={{ __html: 'window.dataLayer = window.dataLayer || [{}];function gtag() {dataLayer.push(arguments);}gtag("consent", "default", {ad_storage: "denied",analytics_storage: "granted",});gtag("set", "ads_data_redaction", true);' }}
                        />
                        <script
                            dangerouslySetInnerHTML={{
                                __html: `
                                    window.firstConsentUpdateOccurred = false
                                    var eventify = function (arr, callback) {
                                        arr.push = function (e) {
                                            Array.prototype.push.call(arr, e)
                                            callback(e)
                                        }
                                    }
                                    eventify(window.dataLayer, function (item) {
                                        if (!window.firstConsentUpdateOccurred) {
                                            var argumentArray = Array.from(item);
                                            if (argumentArray && argumentArray.length === 3 && argumentArray[0] === 'consent' && argumentArray[1] === 'update') {
                                                window.dispatchEvent(new Event('consentUpdated'));
                                                window.dispatchEvent(new CustomEvent('consentUpdatedHero', { detail: argumentArray[2] }));
                                                window.firstConsentUpdateOccurred = true;
                                            }
                                        }});
                                    `
                            }}
                        />
                        <script dangerouslySetInnerHTML={{ __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;j.addEventListener('load', function() {var _ge = new CustomEvent('gtm_loaded', { bubbles: true });d.dispatchEvent(_ge);});f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmid}');` }} />
                        <noscript
                            dangerouslySetInnerHTML={{
                                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmid}}" height="0" width="0" style="display:none;visibility:hidden"/>`
                            }}
                        />
                    </>
                )}
                {heroid && includeHero
                    ? (
                        <>
                            <script dangerouslySetInnerHTML={{ __html: `window.HeroWebPluginSettings = { applicationId:"${heroid}"};` }} />
                            <script
                                dangerouslySetInnerHTML={{ __html: '(function(i,a,m,h,e,r,o){i.HeroObject=e;i[e]=i[e]||function(){(i[e].q=i[e].q||[]).push(arguments)},i[e].l=1*new Date;r=a.createElement(m),o=a.getElementsByTagName(m)[0];r.async=1;r.src=h;o.parentNode.insertBefore(r,o)})(window,document,"script","https://cdn.usehero.com/loader.js","hero");' }}
                            />
                        </>
                    )
                    : (
                        <script
                            type='text/javascript'
                            dangerouslySetInnerHTML={{ __html: 'window.hero = function (){}' }}
                        />
                    )}
                {!!onetrustid
                && (
                    <>
                        <script
                            src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
                            type='text/javascript'
                            charSet='UTF-8'
                            data-domain-script={onetrustid}
                        />
                        <script
                            type='text/javascript'
                            dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper(){}' }}
                        />
                    </>
                )}
                <script type="text/javascript" async src="https://510001712.collect.igodigital.com/collect.js"></script>
                <script dangerouslySetInnerHTML={{
                        __html: `
                        var ScarabQueue = ScarabQueue || [];
                        (function(id) {
                        if (document.getElementById(id)) return;
                        var js = document.createElement('script'); js.id = id;
                        js.src = '//cdn.scarabresearch.com/js/1C95353558C9EC8C/scarab-v2.js';
                        var fs = document.getElementsByTagName('script')[0];
                        fs.parentNode.insertBefore(js, fs);
                        })('scarab-js-api');
                        `}} />
                    
            </Head>
    )
}

export default ThirdPartyScripts
